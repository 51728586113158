import React from 'react';
import Helmet from 'react-helmet';
import { Content, Header, Layout, SectionTitle } from '../components';

import SiteConfig from '../../config/SiteConfig';
import { HeaderItem } from '../components/Header';
import { Link } from 'gatsby';
import styled from 'styled-components';

const ClearP = styled.p`
    clear: left;
`;

const SwickContent = styled(Content)`
    background-color: #ffa169;
    line-height: 1.4rem;
    margin: -24px;
    padding: 24px;

    & p:first-child {
        margin-top: 0;
    }

    a {
        text-decoration: underline;
    }
`;

const Rule = styled.hr`
    border-style: solid;
    border-color: #544468;
    border-width: 1px 0 0 0;
`;


export default function swickProjectPage() {
//  const [value, setValue] = React.useState<FormObject>({});
  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [serverResponse, setServerResponse] = React.useState('');
  const [serverError, setServerError] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  function handleName(event: React.ChangeEvent<HTMLInputElement>) {
    setServerResponse('');
    setServerError(false);
    setSubmitted(false);
    setName(event.target.value);
  }

  function handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
    setServerResponse('');
    setServerError(false);
    setSubmitted(false);
    setEmail(event.target.value);
  }

  // When the form is submitted, send the form values
  // to our function for processing.
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSubmitted(true);
    setServerError(false);
    setServerResponse('Please wait...');
    const response = await window
      .fetch('/.netlify/functions/sendSubscriptionEmail', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(
          {
            subscriberName: name,
            subscriberEmail: email,
          },
        ),
      });

    console.info(`Type: ${response.type}`);
    if (response.status === 404 || response.status >= 500) {
      setServerError(true);
      setServerResponse(`${response.status}: Unable to sign up at the moment. Try again later I suppose?`);
    } else {
      setServerError(false);
      setServerResponse(`${await response.text()}`);
    }
  }

  return (
    <Layout neighbo={true}>
      <Helmet title={`Contact | ${SiteConfig.siteTitle}`}/>
      <Header linkToHomepage={true} selected={HeaderItem.Neighbo}>
        <SectionTitle><del>My next project</del> Neighbo!</SectionTitle>
      </Header>
      <SwickContent>
        <p><b>September 2024:</b> Neighbo is the name for my next project. Work is underway now and
          I expect to release the first version sometime in 2025. Follow along at the dedicated <a
            href="https://blog.neighbo.au/">Neighbo Blog on blog.neighbo.au</a></p>

        <Rule />

        <p><b>May 2024:</b> I'm soon starting an online guide to Melbourne. I have not established
          exactly what I am making yet, but I know that I want an alternative to the monoculture of
          big tech that has become the gatekeeper of local community information.</p>
        <p>Some of you have told me that you want me to keep you updated! Sign up <del>below</del> <a
          href="https://blog.neighbo.au/">on blog.neighbo.au</a> and I'll email you when there's
          something to show. Don't expect to hear much for a while... I expect to spend a couple of
          months exploring ideas before I'll be on course.</p>
        <ClearP><br/><Link to="/">Return to the homepage</Link></ClearP>
      </SwickContent>
    </Layout>
  );
}
